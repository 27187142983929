.btn{
    box-sizing: border-box;
    border: 1px solid;
    text-transform: uppercase;
    transition: all .4s ease;
    text-decoration: none;
    appearance: none;
    border-radius: 4px;
    font-size: .75rem!important;
    padding: .5rem .7rem!important;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    display: block;
    color: #fff;
    background: red;
    border-color: red;
    font-weight: 600;
}
.speakers{    
    color: black;
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    padding: 2rem 0;
    position: relative;
    overflow: hidden;}

    .container{
        color: black;
        font-family: "Inter",sans-serif;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        z-index: 1;
        padding: 0 15px;
        margin: 0;
    }
.standard-layout{
    color: black;
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: 0;
    align-items: flex-start;
}
.col-md-12{

    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}
.speaker{   
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    color: white;
    overflow: hidden;
    margin-right: .5%;
    margin-left: .5%;
    width: 49%;
    padding: 0 .5rem 0 0;
    margin-bottom: .5rem;
    height: auto;
    position: relative;}
    .speakers-h2{

        box-sizing: border-box;
        font-size: 1.2rem;
        line-height: normal;
        margin: 0 0 .5rem;
        font-style: normal;
        color: blue;
        font-family: "korolev",sans-serif;
        text-transform: uppercase;
        padding-left: 1rem;
    }
    .speaker-content{
        font-family: "Inter",sans-serif;
        color: white;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1rem;
        text-shadow: 1px 2px 3px #000;
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: .85rem;

    }
    .speaker-content-wrap{
        font-family: "Inter",sans-serif;
        color: white;
        box-sizing: border-box;
        position: absolute;
        overflow: hidden;
        top: 0;
        height: 100%;
        width: 100%;

    }
    .speaker-content-inner{
        text-shadow: 1px 2px 3px #000;
        font-weight: 600;
        font-size: .85rem;
        box-sizing: border-box;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;


    }  .speaker-image{
        font-family: "Inter",sans-serif;
        color: white;
        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        transition: all 1s ease;
        object-fit: cover;


    }