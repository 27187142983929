
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Roboto:wght@300&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Recursive:wght@1000&family=Roboto:wght@300&display=swap');

a {
    text-decoration: none;
    color: white;
    opacity: 1;
    font-family: 'work sans', sans serif;
    font-size: 1.5em;
    font-weight: 400;
    transition: 200ms;
}

a:hover {
    opacity: 0.5;
}

ul {
    padding: 0;
    list-style-type: none;
}



.containerM {
    overflow: scroll;

    margin-top: 50px;
    Display: flex;
    justify-content: center;
    align-items: center;
}

.phone {
    background-color: #36383F;
    border-radius: 40px;
    width: 300px;
    height: 600px;
    Display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 30px 50px 100px #85888C;
}

.content2 {

    /* border: 1px solid #36383F; */
    /* border-radius: 20px; */
    width: 100%;
    height: auto;
    background-color: black;
    /* overflow: hidden; */
    /* margin-top: 1em; */
    position:fixed;
    z-index: 1000;
    /* margin-bottom: 14em; */


}

nav {

    background-color: black;
    height: 65px;
    /* margin-bottom: 2em; */
    margin-top: 2em;
}


#menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    z-index: 110000;
    /* -webkit-user-select: none; */
    /* user-select: none; */
}

#menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

#menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    /* border-radius: 3px; */
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}


#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
}

#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

#menu {

    position: absolute;
    width: 220px;
    height: 2000px;
    top: 70px;
    /* box-shadow: 0 0 10px #85888C; */
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 25px;
    background-color: black;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    z-index: 50000;

}

#menu li {
    padding: 1px 0;
    transition-delay: 2s;
    color: whitesmoke;
}

#menuToggle input:checked~ul {
    transform: none;
}

.texts {
    /* right: 40px; */
font-size: 40px;
    text-align: end;
    margin-right: 30px ;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
font-family: 'Recursive', sans-serif;
font-family: 'Roboto', sans-serif;
}

/* .text_div {
    display: flex;
} */

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    color: white;
    margin:0;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Recursive', sans-serif;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: static;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font:white;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Recursive', sans-serif;
    font-family: 'Roboto', sans-serif;
    /* margin-left: 7em; */
    /* margin-top: -3em; */
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    /* text-decoration: none; */
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Recursive', sans-serif;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }

  .hero-btn-red{
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    background: white;
    border: 1px solid;
    transition: all .4s ease;
    appearance: none;
    text-align: center;
    border-radius: 4px;
    font-size: .75rem!important;
    padding: .5rem .7rem!important;
    background-color: red;
    border-color: red;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}