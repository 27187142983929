

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Roboto:wght@300&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Recursive:wght@1000&family=Roboto:wght@300&display=swap');


// @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

// *,
// *::after,
// *::before {
//     margin: 0;
//     padding: 0;
//     box-sizing: inherit;
// }

.html {
  height: 100%;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
   font-size: smaller;
 
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: static;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font:white;
    font-size: 0.2em;

    /* margin-left: 7em; */
    /* margin-top: -3em; */
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    /* text-decoration: none; */
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Recursive', sans-serif;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }

  .hero-btn-red{
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    background: white;
    border: 1px solid;
    transition: all .4s ease;
    appearance: none;
    text-align: center;
    border-radius: 4px;
    font-size: .75rem!important;
    padding: .5rem .7rem!important;
    background-color: red;
    border-color: red;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.body {
  background:#E0E0E0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.navigation {
    &__checkbox {
        display: none;
    }

    &__button {
        background-color: black;
        height: 4.5rem;
        width: 4rem;
        // margin-top: -1em;
        position: fixed;
        top: 0.5rem;
        right: 1rem;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0 1rem 3rem rgba(#000, .1);
        text-align: center;
        cursor: pointer;

    }

    &__background {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        position: fixed;
        top: 1rem;
        right: 1rem;
        background-image: radial-gradient(red,red);
        z-index: 1000;
        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);

    }

    &__nav {
        overflow-y: scroll;
        height: 100vh;
        position: fixed;
        // top: 0;
        // left: 0;
        z-index: 1500;
        visibility: hidden;
        opacity: 0;
        width: 0;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
     
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
        list-style: none;
        text-align: center;
        width: 100%;
    }

    &__item {
        margin: 1rem;
    }

    &__link {
        font-style:italic;
        font-weight: 400;
        font-size: 0.9em;
        transition: 200ms;
        font-family: 'work sans', sans serif;

        // &:link,
        // &:visited {
        //     display: inline-block;
        //     font-size: 1rem;
        //     font-weight: 300;
        //     padding: 1rem 2rem;
        //     color: #fff;
        //     text-decoration: none;
        //     text-transform: uppercase;
        //     background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
        //     background-size: 220%;
        //     transition: all .4s;

        //     span {
        //         margin-right: 1.5rem;
        //         display: inline-block;
        //     }
        // }
        
        // &:hover,
        // &:active {
        //     background-position: 100%;
        //     color: #90CAF9;
        //     transform: translateX(1rem);
        // }
    }


    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(80);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
        visibility: visible
    }


    //ICON
    &__icon {
        position: relative;
        margin-top: 2rem;

        &,
        &::before,
        &::after {
            width: 2.5rem;
            height: 5px;
            background-color:red;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }

        &::before { top: -.8rem; }
        &::after { top: .8rem; }
    }

    &__button:hover &__icon::before {
        top: -1rem;
    }

    &__button:hover &__icon::after {
        top: 1rem;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}