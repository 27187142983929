.container{
    width:auto
}
.inner-container{
    margin:5px;
    display : inline-flex;
}
.image-container{
    width: 120px;
    height: 130px;
    margin-left: 20px;
}
.image-text{
    /* font-style: bold; */
    font-weight: 4000px;
    position: absolute;
    text-shadow: 1px 2px 3px #000;
    color: white;
    bottom: 2px;
    left: 16px;
    font-size: small;
    margin: 5px !important;

}.image{
    position: relative;
    text-align: center;
    color: white;

}

@media screen and (width > 350px) {
    .image-contain {
        width: 150px;
        height: 150px;
        /* margin-left: 20px; */
    }
}

@media screen and (width > 450px) {
    .image-contain {
        width: 200px;
        height: 200px;
        /* margin-left: 20px; */
    }
}

@media screen and (width > 450px) {
    .image-contain {
        width: 200px;
        height: 200px;
        /* margin-left: 20px; */
    }
}

@media screen and (width > 550px) {
    .image-contain {
        width: 250px;
        height: 250px;
        /* margin-left: 20px; */
    }
}

@media screen and (width > 650px) {
    .image-contain {
        width: 300px;
        height: 300px;
        /* margin-left: 20px; */
    }
}

@media screen and (width > 750px) {
    .image-contain {
        width: 350px;
        height: 350px;
        /* margin-left: 20px; */
    }
}