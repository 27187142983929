.htmlblock {

    color: white;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    padding: 1rem 0;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    z-index: 1;
    background-color: red;
    display: flex;
    align-items: center;
    min-height: 0px;
}

.container {

    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-family: "Inter", sans-serif;
    color: color;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 1;
    max-width: 960px;
    padding: 0 1rem;
}

.row {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-family: "Inter", sans-serif;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: 0;
}

.col-md-5 {

    color: white;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-md-7 {
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    align-items: center;
    color: #fff;
}

@media screen and (width > 850px) {

    /* .row{
        display: inline-flex;
    } */
    .col-md-5 {
        /* display: inline-flex; */
        font-size: 3rem;
    }

    .col-md-7 {
        /* display: inline-flex; */

        font-size: 2rem;
    }
}