.container{
    width:auto
}
.inner-container{
    margin:5px;
    display : inline-flex;
}
.image-containers{
    width: 120px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
}
.image-text{
    /* font-style: bold; */
    font-weight: 4000px;
    position: absolute;
    text-shadow: 1px 2px 3px #000;
    color: white;
    bottom: 2px;
    left: 16px;
    font-size: small;
    margin: 5px !important;

}.images{
    position: relative;
    text-align: center;
    color: white;
    height: 130px;


}


@media screen and (width > 350px) {
    .image-containers {
        width: 150px;
        /* height: 150px; */
        margin-left: 10px;
        margin-right: 10px;    }
}

@media screen and (width > 450px) {
    .image-containers {
        width: 200px;
        /* height: 200px; */
        margin-left: 10px;
        margin-right: 10px;    }
}



@media screen and (width > 550px) {
    .image-containers {
        width: 250px;
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;    }
}

@media screen and (width > 650px) {
    .image-containers {
        width: 300px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;    }
}

@media screen and (width > 750px) {
    .image-containers {
        width: 350px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px; 
    }
}