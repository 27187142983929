.MuiInput{
    /* text-decoration: none; */
    -webkit-font-smoothing: antialiased;
    font: inherit;
    color: red;
    width: 100%;
    border: 0;
    /* border-color: red; */
    height: 1.1876em;
    margin: 0;
    /* background-color: red; */
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    /* animation-name: mui-auto-fill-cancel; */
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    font-size: 20px;
    font-weight: bold;
    box-shadow: none;
}
