.blockline {

    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    color: var(--black-color);
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    display: block;
    text-align: left;
    line-height: 1.4;
    float: left;
    padding: 0;
    margin-right: 4em;
    font-size: .85rem;
    width: 50px;

    height: 10px;
    background: rgb(245, 58, 39);
}

.blocklineblue {

    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    color: var(--black-color);
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    display: block;
    text-align: left;
    line-height: 1.4;
    float: left;
    padding: 0;
    margin-left: 4em;
    font-size: .85rem;
    width: 50px;

    height: 10px;
    background: rgb(39, 97, 245);
}

.blocklineyellow {

    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    color: var(--black-color);
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    display: block;
    text-align: left;
    line-height: 1.4;
    float: right;
    padding: 0;
    margin-right: 4em;
    font-size: .85rem;
    width: 50px;

    height: 10px;
    background: rgb(245, 245, 39);
}

.blocklinegreen {

    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    color: var(--black-color);
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    display: block;
    text-align: left;
    line-height: 1.4;
    float: left;
    padding: 0;
    margin-left: 4em;
    font-size: .85rem;
    width: 50px;

    height: 10px;
    background: rgb(39, 245, 221);
}


@media screen and (width > 850px) {
    .blockline {
        height: 12px;
        width: 70px;
        margin-right: 5em;
        margin-bottom: 5em;
    }

    .blocklinegreen {
        height: 12px;
        width: 70px;
        margin-right: 5em;
        margin-bottom: 5em;
    }

    .blocklineyellow {
        height: 12px;
        width: 70px;
        margin-right: 5em;
        margin-bottom: 5em;
    }

    .blocklineblue {
        height: 12px;
        width: 70px;
        margin-right: 5em;
        margin-bottom: 5em;
    }
}