.slider{

    color: black;
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 2rem 0;
}

.slider-h2{
    list-style: none;
    box-sizing: border-box;
    line-height: normal;
    font-style: normal;
    text-transform: uppercase;
    margin: 0;
    font-size: 3.5rem;
    font-family: "korolev",sans-serif;
    color: black;
}
.slider-p{   
    font-family: "Inter",sans-serif;
    list-style: none;
    box-sizing: border-box;
    line-height: 1.4;
    font-size: 1.5rem;
    margin: 0;
    color: black;
}

/* slider qand a */
.sliderqa{
    background-color: red;
        color: white;
        font-family: "Inter",sans-serif;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        padding: 2rem 0;
    }
    .slider-p-qa{   
        font-family: "Inter",sans-serif;
        list-style: none;
        box-sizing: border-box;
        line-height: 1.4;
        font-size: 1.5rem;
        margin: 0;
        color: white;

    }

    .slider-h2-qa{
        list-style: none;
        box-sizing: border-box;
        line-height: normal;
        font-style: normal;
        text-transform: uppercase;
        margin: 2em;
        margin-left: 2em;
        font-size: 1.5rem;
        font-family: "korolev",sans-serif;
        color: white;
        font-style: italic;
        margin-right:1%;
    }

    .main{
    /* margin: 4em; */
    margin-bottom: 2em; 
    /* margin-right:4em; */
    /* width:30px; */
    height:100px;
    display: flex;
    /* padding-left: 6em; */
    align-items: center;
    justify-content: center;
    width: 426px;
    /* box-sizing: border-box; */
    /* flex-shrink: 0; */
    /* position: relative; */

    }

    .main_img{
   width: 200px;

    }
    
   
    
    @media screen and (width > 1000px) {
        .main_img{
            width: 200px;
         
             }
    }
    
    @media screen and (width > 1000px) {
        .slider{
height:400px;
        }
    }